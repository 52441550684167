/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Parse } from 'services/parse'
import { Theme, ThemesFetchResponse } from 'types/api-types'
import getHeaders from 'store/_utils/get-headers'
import { typedRequest } from 'store/_utils/api-fetch'
import { RootState } from 'store'
import themesAdapter from './themes-adapter'

const api = import.meta.env.VITE_TC_INSIGHTS_API as string

export const fetchThemes = createAsyncThunk(
    'themes/fetchThemes',
    async (_, { rejectWithValue }) => {
        const appId = Parse.User.current().attributes.app.id as string
        const headers = await getHeaders()
        let response

        response = await typedRequest<ThemesFetchResponse>(
            `${api}/v3/apps/${appId}/themes`,
            {
                method: 'GET',
                headers: { ...headers, 'Content-Type': 'application/json' },
            }
        )
        if (response.msg === 'themes not found') {
            return rejectWithValue(response.msg)
        }
        return response
    }
)

export const updateActiveTheme = createAsyncThunk(
    'themes/updateActiveTheme',
    async (
        { data }: { fontId: string | null; data: Theme; v2Enabled: boolean },
        { getState, rejectWithValue }
    ) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const headers = await getHeaders()
        const activeThemeId = state.themes.activeThemeId as string

        const response = await typedRequest<ThemesFetchResponse>(
            `${api}/v3/apps/${appId}/themes/${activeThemeId}`,
            {
                method: 'PUT',
                headers: { ...headers, 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            }
        )
        if (response.msg === 'themes not found') {
            return rejectWithValue(response.msg)
        }

        return {
            data: response,
            activeThemeId,
            fontAssetID:
                data.v2Config?.tokens?.typography?.defaultFont?.fontAssetID,
        }
    }
)

export const updateProductImages = createAsyncThunk(
    'themes/updateProductImages',
    async (productImages, { getState, rejectWithValue }) => {
        try {
            const state = getState() as RootState
            const appId = state.app.data.id
            const headers = await getHeaders()
            const activeThemeId = state.themes.activeThemeId as string

            const activeTheme = state.themes.entities?.[activeThemeId]

            const data = {
                ...activeTheme,
                productImages,
            }

            const response = await typedRequest<ThemesFetchResponse>(
                `${api}/v3/apps/${appId}/themes/${activeThemeId}`,
                {
                    method: 'PUT',
                    headers: { ...headers, 'Content-Type': 'application/json' },
                    body: JSON.stringify(data),
                }
            )

            return { data: response, activeThemeId }
        } catch (error) {
            throw rejectWithValue(error)
        }
    }
)

export const updateCartIcon = createAsyncThunk(
    'themes/updateCartIcon',
    async (cart, { getState }) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const headers = await getHeaders()
        const activeThemeId = state.themes.activeThemeId as string

        const activeTheme = state.themes.entities?.[activeThemeId]

        const data = {
            ...activeTheme,
            tokens: {
                ...activeTheme?.tokens,
                iconography: { ...activeTheme?.tokens?.iconography, cart },
            },
        }

        const response = await typedRequest<ThemesFetchResponse>(
            `${api}/v3/apps/${appId}/themes/${activeThemeId}`,
            {
                method: 'PUT',
                headers: { ...headers, 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            }
        )

        return { data: response, activeThemeId }
    }
)

export const updateLoyaltyIcon = createAsyncThunk(
    'themes/updateLoyaltyIcon',
    async (loyalty: any, { getState }) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const headers = await getHeaders()
        const activeThemeId = state.themes.activeThemeId as string

        const activeTheme = state.themes.entities?.[activeThemeId]

        const data = {
            ...activeTheme,
            iconography: { ...activeTheme?.iconography, loyalty },
        }

        const response = await typedRequest<ThemesFetchResponse>(
            `${api}/v3/apps/${appId}/themes/${activeThemeId}`,
            {
                method: 'PUT',
                headers: { ...headers, 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            }
        )

        return { data: response, activeThemeId }
    }
)
interface ThemesState {
    loading: 'idle' | 'pending' | 'failed'
    initialized: boolean
    error: null | string
    activeThemeId: string | null
    entities: object
    ids: string[]
}

const themesSlice = createSlice({
    name: 'themes',
    initialState: themesAdapter.getInitialState<ThemesState>({
        initialized: false,
        loading: 'pending',
        error: null,
        activeThemeId: null,
        entities: {},
        ids: [],
    }),
    reducers: {
        setThemesLoading: (state, action) => {
            state.loading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchThemes.pending, (state) => {
            state.loading = 'pending'
            state.initialized = false
        })
        builder.addCase(fetchThemes.fulfilled, (state, action) => {
            themesAdapter.upsertMany(state, action.payload)
            let activeTheme = action.payload.find((theme) => {
                return theme.isActive
            })

            if (!activeTheme) {
                activeTheme = action.payload[0]
            }
            const activeThemeId = activeTheme._id

            state.activeThemeId = activeThemeId

            state.loading = 'idle'
            state.error = null
            state.initialized = true
        })
        builder.addCase(fetchThemes.rejected, (state, action) => {
            state.loading = 'failed'
            state.error = action.payload || 'Rejected'
            state.initialized = true
        })
        builder.addCase(updateActiveTheme.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateActiveTheme.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            state.initialized = true
            themesAdapter.updateOne(state, {
                id: action.payload.activeThemeId,
                changes: action.payload.data,
            })
        })
        builder.addCase(updateActiveTheme.rejected, (state, action) => {
            state.loading = 'failed'
            state.error = action.error.message || ''
            state.initialized = true
        })
        builder.addCase(updateCartIcon.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateCartIcon.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            state.initialized = true
            themesAdapter.updateOne(state, {
                id: action.payload.activeThemeId,
                changes: action.payload.data,
            })
        })
        builder.addCase(updateCartIcon.rejected, (state, action) => {
            state.loading = 'failed'
            state.error = action.error.message || ''
            state.initialized = true
        })
        builder.addCase(updateLoyaltyIcon.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateLoyaltyIcon.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            state.initialized = true
            themesAdapter.updateOne(state, {
                id: action.payload.activeThemeId,
                changes: action.payload.data,
            })
        })
        builder.addCase(updateLoyaltyIcon.rejected, (state, action) => {
            state.loading = 'failed'
            state.error = action.error.message || ''
            state.initialized = true
        })
        builder.addCase(updateProductImages.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateProductImages.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            state.initialized = true
            themesAdapter.updateOne(state, {
                id: action.payload.activeThemeId,
                changes: action.payload.data,
            })
        })
        builder.addCase(updateProductImages.rejected, (state, action) => {
            state.loading = 'failed'
            state.error = action.error.message || ''
            state.initialized = true
        })
    },
})

export const { setThemesLoading } = themesSlice.actions
export default themesSlice.reducer
