/* eslint-disable no-param-reassign */
import {
    createAsyncThunk,
    createSlice,
    EntityState,
    SerializedError,
} from '@reduxjs/toolkit'
import {
    CreateAssetResponse,
    CreateAssetRequest,
    AssetType,
} from 'types/dashboard-types'
import getHeaders from 'store/_utils/get-headers'
import { RootState } from 'store'
import { Parse } from 'services/parse'
import getBase64FromUrl from 'pages/BrandKit/utils/helpers/get-base64-from-url'
import { loadFontInitialFont } from 'pages/BrandKit/utils/helpers/fonts'
import { FontVariant, Icon } from 'types/api-types'
import {
    createAppIcon,
    fetchAppIcons,
    deleteAsset,
    replaceIcon,
    fetchDefaultFonts,
    fetchCustomFontAssets,
    fetchDefaultIcons,
} from './assets-api'
import assetsIconsAdapter from './assets-icons-adapter'
import assetsFontsAdapter from './assets-fonts-adapter'
import parseFontAttributes from 'pages/BrandKit/components/BrandKitTypography/utils/parse-font-attributes'

const assetsURL = import.meta.env.VITE_TC_ASSETS_API as string
const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB
interface AssetState {
    data: CreateAssetResponse | Record<string, never>
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean

    selfServiceFont: {
        file: File | string
        fontFamily: string
        postScriptName?: string
        fontSubfamily?: string
        fullName?: string
        fontAssetID: string
    } | null
    customFonts: EntityState<FontVariant> | null
    defaultFonts: any
    defaultIcons: any
    customIcons: EntityState<Icon> | null
}

const initialState: AssetState = {
    data: {},
    loading: 'idle',
    error: null,
    initialized: false,
    selfServiceFont: null,
    customFonts: assetsFontsAdapter.getInitialState(),
    defaultFonts: [],
    defaultIcons: [],
    customIcons: assetsIconsAdapter.getInitialState(),
}

export interface JSONResponse extends Response {
    json(): Promise<CreateAssetResponse>
}

const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
        if (!file) reject(new Error('No file provided'))
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener('load', () => resolve(reader.result))
        reader.addEventListener('error', (error) => reject(error))
    })

type CreateAssetParams = {
    file: File
    path: string
    maxFileSize?: number
    type?: AssetType
}

export const fetchIconAssets = createAsyncThunk(
    'assets/fetchIconAssets',
    async () => {
        const appId = Parse.User.current().attributes.app.id as string

        const fetchResponse = await fetchAppIcons(appId)
        return { customIcons: fetchResponse }
    }
)

export const fetchDefaultIconAssets = createAsyncThunk(
    'assets/fetchDefaultIconAssets',
    async () => {
        const appId = Parse.User.current().attributes.app.id as string

        const fetchResponse = await fetchDefaultIcons(appId)
        return { defaultIcons: fetchResponse.assets }
    }
)

export const fetchFontAssets = createAsyncThunk(
    'assets/fetchFontAssets',
    async () => {
        const appId = Parse.User.current().attributes.app.id as string

        const customFonts = await fetchCustomFontAssets(appId)
        const defaultFonts = await fetchDefaultFonts(appId)

        return { customFonts: Object.values(customFonts), defaultFonts }
    }
)

export const createIconAsset = createAsyncThunk(
    'assets/createIconAsset',
    async ({
        base64String,
        fileName,
    }: {
        base64String: string
        fileName: string
    }) => {
        const appId = Parse.User.current().attributes.app.id as string
        const createIconResponse = await createAppIcon(
            appId,
            base64String,
            fileName
        )
        return { icon: createIconResponse }
    }
)

export const removeIconAsset = createAsyncThunk(
    'assets/removeIconAsset',
    async (iconId: string) => {
        const appId = Parse.User.current().attributes.app.id as string
        await deleteAsset(appId, iconId)
        return { iconId }
    }
)

export const removeFontAsset = createAsyncThunk(
    'assets/removeFontAsset',
    async ({ fontId, entityIds }) => {
        const appId = Parse.User.current().attributes.app.id as string
        const res = await deleteAsset(appId, fontId)
        return { fontId: res, entityIds: entityIds }
    }
)

export const replaceIconAsset = createAsyncThunk(
    'assets/replaceIconAsset',
    async (iconValues: {
        id: string
        base64String: string
        fileName: string
    }) => {
        const appId = Parse.User.current().attributes.app.id as string

        const data = await replaceIcon(appId, iconValues)
        return data
    }
)

export const fetchFontAsset = createAsyncThunk(
    'assets/fetchFontAsset',
    async (_, { getState }) => {
        const state = getState() as RootState
        const appId = Parse.User.current().attributes.app.id as string
        const activeThemeId = state.themes.activeThemeId as string

        const fontAssetID =
            state.themes.entities[activeThemeId]?.tokens?.typography
                ?.defaultFont?.fontAssetID
        if (!fontAssetID) return null
        const headers = await getHeaders()

        const response = await fetch(`${assetsURL}/${fontAssetID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
                'app-id': appId,
            },
        })

        const data = await response.json()

        const file = (await getBase64FromUrl(data.url)) as string
        await loadFontInitialFont({
            fontFamily: data.metadata?.fontMetaData?.[0].fontFamily,
            url: data.url,
        })

        const metadata = data.metadata?.fontMetaData?.[0]
        return {
            file,
            url: data.url,
            fontFamily: metadata.fontFamily,
            fontSubfamily: metadata.fontSubfamily,
            fullName: metadata.fullName,
            postScriptName: metadata.postScriptName,
            fontAssetID: data.id,
        }
    }
)

export const createFontAsset = createAsyncThunk(
    'assets/createFontAsset',
    async (fontFile: any, { getState }) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const headers = await getHeaders()

        const body = {
            type: 'font',
            appId,
            data: fontFile,
        }

        const response: JSONResponse = await fetch(assetsURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(body),
        })
        const res = await response.json()
        const file = (await getBase64FromUrl(res.url)) as string
        const metadata = {
            ...res.metadata.fontMetaData?.[0],
            fontAssetID: res.id,
        }
        return metadata
    }
)

export const createFontAssets = createAsyncThunk(
    'assets/createFontAssets',
    async ({ fontFile, fontFamily }, { getState }) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const headers = await getHeaders()

        const body = {
            type: 'font',
            appId,
            fontFamily,
            data: fontFile,
        }

        const response: JSONResponse = await fetch(assetsURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(body),
        })
        const res = await response.json()
        const file = (await getBase64FromUrl(res.url)) as string
        const metadata = res.metadata.fontMetaData.map((fontData) => {
            const bestGuessAttributes = parseFontAttributes(fontData)
            return {
                ...fontData,
                fontFamily: res.metadata.fontFamily,
                fileType: res.metadata.fileType,
                fontFace: res.metadata.fontFamily,
                fontAssetID: res.id,
                fontWeight: bestGuessAttributes?.weight ?? 400,
                fontStyle: bestGuessAttributes?.style ?? 'normal',
                url: res.url,
                file: res.url,
            }
        })
        return { metadata, res }
    }
)

export const updateFontAsset = createAsyncThunk(
    'assets/updateFontAsset',
    async (fontFile, { getState }) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const headers = await getHeaders()

        const body = {
            type: 'font',
            appId,
            data: fontFile,
        }

        const response: JSONResponse = await fetch(assetsURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(body),
        })
        const res = await response.json()
        const file = (await getBase64FromUrl(res.url)) as string
        const metadata = res.metadata.fontMetaData[0]
        return {
            file,
            fontFamily: metadata.fontFamily,
            fontSubfamily: metadata.fontSubfamily,
            fullName: metadata.fullName,
            postScriptName: metadata.postScriptName,
            fontAssetID: res.id,
        }
    }
)

export const createAsset = createAsyncThunk(
    'assets/createAsset',
    async (
        params: CreateAssetParams,
        { getState, rejectWithValue }
    ): Promise<CreateAssetResponse> => {
        const maxFileSize = params.maxFileSize || MAX_FILE_SIZE
        if (params.file.size > maxFileSize) {
            return rejectWithValue(
                new Error(
                    `File size should not exceed ${
                        maxFileSize / (1024 * 1024)
                    } MB`
                )
            )
        }

        const state = getState() as RootState
        const appId = state.app.data.id
        let data = ''

        try {
            data = await toBase64(params.file)
        } catch (error) {
            return rejectWithValue(error)
        }

        const body: CreateAssetRequest = {
            type: params.type || 'image',
            appId,
            data,
            path: params.path,
        }
        const headers = await getHeaders()
        const response: JSONResponse = await fetch(assetsURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(body),
        })

        if (!response.ok) {
            if (response.status === 400)
                return rejectWithValue(new Error('Invalid file type'))

            return rejectWithValue(new Error('Failed to create asset'))
        }
        
        const res = await response.json()
        return res
    }
)

export const updateFontMetadata = createAsyncThunk(
    'assets/updateFontMetadata',
    async ({ assetId, metadata }, { getState, rejectWithValue }) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const body = {
            type: 'font',
            appId,
            fontWeight: metadata.fontWeight,
            fontFamily: metadata.fontFamily,
            fontStyle: metadata.fontStyle,
        }
        const headers = await getHeaders()

        const response = await fetch(`${assetsURL}/${assetId}/metadata`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(body),
        })
        const res = await response.json()
        return {
            id: metadata.postScriptName,
            changes: {
                fontFamily: res.metadata.fontFamily,
                fontWeight: res.metadata.fontMetaData[0].fontWeight,
                fontStyle: res.metadata.fontMetaData[0].fontStyle,
            },
        }
    }
)

const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createAsset.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(createAsset.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.initialized = true
            state.data = action.payload
        })
        builder.addCase(createAsset.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(fetchFontAsset.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchFontAsset.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.initialized = true
            state.selfServiceFont = action.payload
        })
        builder.addCase(fetchFontAsset.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })

        builder.addCase(createFontAsset.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(createFontAsset.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.selfServiceFont = action.payload
            state.initialized = true
        })
        builder.addCase(createFontAsset.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(createFontAssets.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(createFontAssets.fulfilled, (state, action) => {
            state.loading = 'fufilled'

            state.customFonts = assetsFontsAdapter.addMany(state.customFonts, {
                ...action.payload.metadata,
            })
            state.initialized = true
        })
        builder.addCase(createFontAssets.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(fetchIconAssets.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchIconAssets.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.customIcons = assetsIconsAdapter.setAll(
                state.customIcons,
                action.payload.customIcons.assets
            )
            state.initialized = true
        })
        builder.addCase(fetchIconAssets.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(fetchFontAssets.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchFontAssets.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.customFonts = assetsFontsAdapter.setAll(
                state.customFonts,
                action.payload.customFonts
            )
            state.defaultFonts = action.payload.defaultFonts
            state.initialized = true
        })
        builder.addCase(fetchFontAssets.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(fetchDefaultIconAssets.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchDefaultIconAssets.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.defaultIcons = action.payload.defaultIcons
        })
        builder.addCase(fetchDefaultIconAssets.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(createIconAsset.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(createIconAsset.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.customIcons = assetsIconsAdapter.addOne(
                state.customIcons,
                action.payload.icon
            )
            state.initialized = true
        })
        builder.addCase(createIconAsset.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(removeIconAsset.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(removeIconAsset.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.customIcons = assetsIconsAdapter.removeOne(
                state.customIcons,
                action.payload.iconId
            )
            state.initialized = true
        })
        builder.addCase(removeIconAsset.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(removeFontAsset.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(removeFontAsset.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.customFonts = assetsFontsAdapter.removeMany(
                state.customFonts,
                action.payload.entityIds
            )
            state.initialized = true
        })
        builder.addCase(removeFontAsset.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(replaceIconAsset.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(replaceIconAsset.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.customIcons = assetsIconsAdapter.updateOne(
                state.customIcons,
                {
                    id: action.payload.id,
                    changes: action.payload,
                }
            )
            state.initialized = true
        })
        builder.addCase(replaceIconAsset.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(updateFontMetadata.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateFontMetadata.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.customFonts = assetsFontsAdapter.updateOne(
                state.customFonts,
                {
                    id: action.payload.id,
                    changes: action.payload.changes,
                }
            )
            state.initialized = true
        })
        builder.addCase(updateFontMetadata.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
    },
})

export const selectIsAssetLoading = (state: RootState): boolean =>
    state.assets.loading === 'pending'

export default assetsSlice.reducer
