/* eslint-disable no-param-reassign */ // Immer allows us to directly mutate the state here
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SingleModalState {
    isOpen: boolean
    id?: string | null
    saveFunc?: (() => any | Promise<any>) | null
    discardFunc?: (() => any | Promise<any>) | null
    nextPath?: string | null
    nextPathSection?: string | null
    nextPathSubSection?: string | null
    activePathSegment?: string | null
    fluidState?: unknown
}

interface ModalsState {
    appPreviewModal: SingleModalState
    logoutModal: SingleModalState
    saveChangesModal: SingleModalState
    PLPPreviewer: SingleModalState
}

const initialState: ModalsState = {
    appPreviewModal: {
        isOpen: false,
        id: null,
    },
    logoutModal: {
        isOpen: false,
    },
    saveChangesModal: {
        isOpen: false,
        saveFunc: null,
        discardFunc: null,
        nextPath: null,
        nextPathSection: null,
        nextPathSubSection: null,
        activePathSegment: null,
        fluidState: null,
    },
    PLPPreviewer: {
        isOpen: false,
    },
}

const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        // Dedicated actions for appPreviewModal
        openAppPreviewModal: (state, action: PayloadAction<{ id: string }>) => {
            state.appPreviewModal.isOpen = true
            state.appPreviewModal.id = action.payload.id
        },
        closeAppPreviewModal: (state) => {
            state.appPreviewModal.isOpen = false
            state.appPreviewModal.id = null
        },
        openLogoutModal: (state) => {
            state.logoutModal.isOpen = true
        },
        closeLogoutModal: (state) => {
            state.logoutModal.isOpen = false
        },
        openSaveChangesModal: (state, action) => {
            state.saveChangesModal.nextPath = action.payload.nextPath
            state.saveChangesModal.nextPathSection =
                action.payload?.nextPathSection
            state.saveChangesModal.nextPathSubSection =
                action.payload?.nextPathSubSection
            state.saveChangesModal.activePathSegment =
                action.payload?.activePathSegment
            state.saveChangesModal.saveFunc = action.payload.saveFunc
            state.saveChangesModal.discardFunc = action.payload.discardFunc
            state.saveChangesModal.isOpen = true
            state.saveChangesModal.fluidState = action.payload.fluidState
        },
        closeSaveChangesModal: (state) => {
            state.saveChangesModal.isOpen = false
            state.saveChangesModal.nextPath = null
            state.saveChangesModal.nextPathSection = null
            state.saveChangesModal.nextPathSubSection = null
            state.saveChangesModal.activePathSegment = null
            state.saveChangesModal.saveFunc = null
            state.saveChangesModal.discardFunc = null
            state.saveChangesModal.fluidState = null
        },
        openPLPPreviewer: (state) => {
            state.PLPPreviewer.isOpen = true
            document.body.classList.add('noscroll')
        },
        closePLPPReviewer: (state) => {
            state.PLPPreviewer.isOpen = false
            document.body.classList.remove('noscroll')
        },
        // ... other modals reducers ...
    },
})

export const {
    openAppPreviewModal,
    closeAppPreviewModal,
    openLogoutModal,
    closeLogoutModal,
    openSaveChangesModal,
    closeSaveChangesModal,
    openPLPPreviewer,
    closePLPPReviewer,
    // For new modals: openNewModalName, closeNewModalName
} = modalsSlice.actions

export default modalsSlice.reducer
