/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { AppConfigConfig, AppConfigFlags, Language } from 'types/api-types'
import {
    AppConfigState,
    NavigationConfig,
    TitlebarConfig,
} from 'types/dashboard-types'
import { Parse } from 'services/parse'
import { typedRequest } from 'store/_utils/api-fetch'
import getHeaders from 'store/_utils/get-headers'
import { shapeAppConfigPayload } from 'store/AppConfig/utils/helpers/shape-app-config-payload'
import updateMenuItems from 'pages/BrandKit/utils/helpers/update-menu-items'
import { NavigationForm as NavigationFormInterface } from 'types/forms/navigation-types'
import axios from 'axios'

const api = import.meta.env.VITE_TC_INSIGHTS_API as string

const initialState: AppConfigState = {
    loading: 'pending',
    initialized: false,
    error: null,
    data: {
        defaultLanguage: 'en',
        navigation: {
            type: 'sidemenu',
            data: {
                showTabTitles: true,
                items: [],
                sideMenuBackgroundImage: { type: '', url: '' },
                showMenuIcons: true,
            },
            styles: {
                tabSelectedColor: '',
                tabUnselectedColor: '',
                tabBarBackgroundColor: '',
            },
        },
        titlebar: {
            data: {
                start: null,
                center: null,
                end: null,
            },
        },
        flags: {
            'user-gating-cart': false,
        },
        corePageOverrides: {},
    },
}

export const fetchAppConfig = createAsyncThunk(
    'appConfig/fetchAppConfig',
    async (
        language: Language = defaultLanguage,
        { getState, rejectWithValue }
    ) => {
        const appId = Parse.User.current().attributes.app.id as string
        const defaultHeaders = await getHeaders()
        const headers = {
            ...defaultHeaders,
            'Content-Type': 'application/json',
            'Accept-Language': language.isoCode.toLowerCase(),
        }

        try {
            const response = await fetch(`${api}/v2/app-config/${appId}`, {
                method: 'GET',
                headers,
            })

            // Check if the response status is not OK
            if (!response.ok) {
                const errorData = await response.json()
                return rejectWithValue(errorData.msg || 'Server Error')
            }

            const responseData = await response.json()

            const flags = responseData?.flags ?? { 'user-gating-cart': false }
            return {
                data: {
                    defaultLanguage: responseData?.defaultLanguage
                        ? responseData?.defaultLanguage.toLowerCase()
                        : 'en',
                    navigation: responseData?.navigation,
                    titlebar: responseData?.titlebar,
                    flags,
                    corePageOverrides: responseData?.corePageOverrides,
                },
            }
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred')
        }
    }
)

export const updateAppConfig = createAsyncThunk(
    'appConfig/updateAppConfig',
    async (
        {
            appConfigUpdates,
            language,
            defaultLanguage,
        }: {
            appConfigUpdates: NavigationFormInterface
            language: Language
            defaultLanguage: Language
        },
        { getState, rejectWithValue }
    ) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const defaultHeaders = await getHeaders()
        const headers = {
            ...defaultHeaders,
            'Content-Type': 'application/json',
            'Accept-Language': language.isoCode.toLowerCase(),
        }

        const requestBody = shapeAppConfigPayload(
            appConfigUpdates,
            state.appConfig,
            defaultLanguage
        )

        try {
            const response = await fetch(`${api}/v2/app-config/${appId}`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(requestBody),
            })
            if (!response.ok) {
                const errorData = await response.json()
                return rejectWithValue(errorData.msg || 'Server Error')
            }
            const responseData = await response.json()
            const flags = responseData?.flags ?? {
                'user-gating-cart': false,
            }
            return {
                defaultLanguage: responseData?.defaultLanguage
                    ? responseData?.defaultLanguage.toLowerCase()
                    : 'en',
                navigation: responseData?.navigation,
                titlebar: responseData?.titlebar,
                flags,
            }
        } catch (err) {
            return rejectWithValue(err || 'Server Error')
        }
    }
)

export const updateAppConfigField = createAsyncThunk(
    'appConfig/updateAppConfigField',
    async (
        {
            field,
            value,
        }: {
            field: string
            value: TitlebarConfig | NavigationConfig | AppConfigFlags
        },
        { getState, rejectWithValue }
    ) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const language = state.appConfig.data?.defaultLanguage
        const headers = await getHeaders()
        const requestBody = {
            ...state.appConfig.data,
            [field]: value,
        }

        try {
            const response = await fetch(`${api}/v2/app-config/${appId}`, {
                method: 'PUT',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                    'Accept-Language': language.toLowerCase(),
                },
                body: JSON.stringify(requestBody),
            })

            // Check if the response status is not OK
            if (!response.ok) {
                const errorData = await response.json()
                return rejectWithValue(errorData.msg || 'Server Error')
            }
            const responseData = await response.json()

            const flags = responseData?.flags ?? { 'user-gating-cart': false }

            return { ...responseData, flags }
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred')
        }
    }
)

export const updateNavigationIcon = createAsyncThunk(
    'appConfig/updateNavigationIcon',
    async (
        {
            iconUrl,
            navItemIndex,
            titlebarIndexes,
            assetID,
        }: {
            iconUrl: string
            navItemIndex: number
            titlebarIndexes: { start: number; end: number }
            assetID: string
        },
        { getState, rejectWithValue }
    ) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const language = state.appConfig.data?.defaultLanguage
        const headers = await getHeaders()
        const updatedNavItems = updateMenuItems(
            state.appConfig.data.navigation.data.items,
            navItemIndex,
            iconUrl,
            assetID
        )

        const updatedStart =
            state.appConfig.data.titlebar?.data?.start.length > 0
                ? [
                      {
                          ...state.appConfig.data.titlebar?.data?.start[0],
                          image:
                              titlebarIndexes.start >= 0
                                  ? { type: 'web', url: iconUrl, assetID }
                                  : {
                                        ...state.appConfig.data.titlebar.data
                                            .start[0]?.image,
                                    },
                      },
                  ]
                : []
        const updatedEnd =
            state.appConfig.data.titlebar?.data?.end.length > 0
                ? [
                      {
                          ...state.appConfig.data.titlebar.data.end[0],
                          image:
                              titlebarIndexes.end >= 0
                                  ? { type: 'web', url: iconUrl, assetID }
                                  : {
                                        ...state.appConfig.data.titlebar.data
                                            .end[0].image,
                                    },
                      },
                  ]
                : []

        const requestBody = {
            ...state.appConfig.data,
            navigation: {
                ...state.appConfig.data.navigation,
                data: {
                    ...state.appConfig.data.navigation.data,
                    items: updatedNavItems,
                },
            },
            titlebar: {
                ...state.appConfig.data.titlebar,
                data: {
                    ...state.appConfig.data.titlebar.data,
                    start: updatedStart,
                    end: updatedEnd,
                },
            },
            flags: { ...state.appConfig.data.flags },
        }

        try {
            const response = await typedRequest<AppConfigConfig>(
                `${api}/v2/app-config/${appId}`,
                {
                    method: 'put',
                    headers: {
                        ...headers,
                        'Content-Type': 'application/json',
                        'Accept-Language': language.toLowerCase(),
                    },
                    body: JSON.stringify(requestBody),
                }
            )

            const flags = response?.flags ?? { 'user-gating-cart': false }

            return { ...response, flags }
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred')
        }
    }
)

const appConfigSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAppConfig.pending, (state) => {
            state.loading = 'pending'
            state.initialized = false
        })
        builder.addCase(fetchAppConfig.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            state.initialized = true
            state.data = action.payload.data
        })
        builder.addCase(fetchAppConfig.rejected, (state, action) => {
            state.loading = 'failed'
            state.initialized = true
            state.error = action.error.message || ''
        })
        builder.addCase(updateAppConfig.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateAppConfig.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            state.initialized = true
            state.data = action.payload
        })
        builder.addCase(updateAppConfig.rejected, (state, action) => {
            state.loading = 'failed'
            state.initialized = true
            state.error = action.error.message || ''
        })
        builder.addCase(updateAppConfigField.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateAppConfigField.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            state.initialized = true
            state.data = action.payload
        })
        builder.addCase(updateAppConfigField.rejected, (state, action) => {
            state.loading = 'failed'
            state.initialized = true
            state.error = action.error.message || ''
        })
        builder.addCase(updateNavigationIcon.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateNavigationIcon.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            state.initialized = true
            state.data = action.payload
        })
        builder.addCase(updateNavigationIcon.rejected, (state, action) => {
            state.loading = 'failed'
            state.initialized = true
            state.error = action.error.message || ''
        })
    },
})

export default appConfigSlice.reducer

export const selectShowMenuIcons = (state) =>
    state.appConfig.data?.navigation?.data?.showMenuIcons ?? true

export const selectAppConfigData = (state: RootState) => state.appConfig.data

export const selectAppConfigInitialized = (state: RootState) =>
    state.appConfig.initialized

export const defaultLanguage = {
    isoCode: 'en',
    endonymName: 'English',
    name: 'English',
}